import {createRouter, createWebHistory} from "vue-router";


import NotFound from "../views/NotFound.vue";

import Inici from "../views/Inici.vue"

import DadesPersona from "../views/DadesPersona.vue"
import DadesPersonaJsp from "../views/DadesPersonaJsp.vue"
import Persones from "../views/Persones.vue"
import DadesCarrec from "../views/DadesCarrec.vue"
import Carrecs from "../views/Carrecs.vue"
import DadesUnitat from "../views/DadesUnitat.vue"
import Jubilats from "../views/Jubilats.vue"
import DadesJubilat from "../views/DadesJubilat.vue"
import Unitats from "../views/Unitats.vue"
import Cerca from "../views/Cerca.vue"
import DadesUEJsp from "../views/DadesUEJsp.vue"
import Ajuda from "../views/Ajuda.vue"


const routes = [
        {
            path: '/',
            component: Inici,
            name: 'inici',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            }
        },
        {
            path: '/ajuda',
            component: Ajuda,
            name: 'ajuda',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            }
        },        
        {
            path: '/tots',
            component: Cerca,
            name: 'cerca',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            }
        },         
        {
            path: '/persona',
            component: Persones,
            name: 'persones',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            },
            props: route => ({ searchField: route.query.q })
        },  
        {
            path: '/carrec',
            component: Carrecs,
            name: 'carrecs',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            },
            props: route => ({ searchField: route.query.q })
        },
        {
            path: '/unitat',
            component: Unitats,
            name: 'unitats',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            },
            props: route => ({ searchField: route.query.q })
        },        
        {
            path: '/jubilat',
            component: Jubilats,
            name: 'jubilats',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            },
            props: route => ({ searchField: route.query.q })
        },                
        {
            path: '/directori/dadesPersona.jsp',
            component: DadesPersonaJsp,
            name: 'dadesPersonaJsp',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            }
        },
        {
            path: '/directori/dadesUE.jsp',
            component: DadesUEJsp,
            name: 'dadesUEJsp',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            }
        },        
        {
            path: '/dadesPersona/:idGauss',
            component: DadesPersona,
            name: 'dadesPersona',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            }
        },     
        {
            path: '/dadesCarrec/:id',
            component: DadesCarrec,
            name: 'dadesCarrec',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            }
        },
        {
            path: '/dadesUnitat/:id',
            component: DadesUnitat,
            name: 'dadesUnitat',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            }
        },                 
        {
            path: '/dadesJubilat/:id',
            component: DadesJubilat,
            name: 'dadesJubilat',
            meta: { 
                title: 'Servei de Directori de la  UPC' 
            }
        },                          
        {
            path: '/:catchAll(.*)',
            name: 'NotFound',
            component: NotFound
        }
    ]
  

const router = new createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
});   
 

router.beforeEach((to, from, next) => {
    document.title = to.meta.title

    next()
});

export default router