<template>
  <div>
    <div class="col-lg contenidorTitolSeccio">
      <div class="titolSeccio">{{$t('Jubilats')}}</div>
      <hr :class="styles_llista_jubilats">
    </div>
    
    <Jubilat v-for="(jubilat,index) in llistaJubilats" 
    :key="jubilat.id" 
    :idioma-pagina="idioma" 
    :dades-jubilat="jubilat"
    :class="(index % 2) == 0 ? 'fonsBlanc_light' : 'fonsGris_light'"/>     
  </div>

</template>
<script>
import { toRefs , computed } from "vue";

import Jubilat from "@/components/Jubilat.vue"

export default {
  components:{
    Jubilat
  },  
  name: "LlistaJubilats",
  props: {
    jubilats: Object,
    idiomaPagina: String
  },

  setup(props) {
      const { idiomaPagina , jubilats } = toRefs(props);
      
      const idioma = computed(() => {
          return idiomaPagina.value
      });

      const llistaJubilats = computed(() => {
        return jubilats?.value?.docs
      });

      const mostrarVeureMesResultats = computed(() => {
        return ((jubilats?.value?.numFound)>5)?true:false;
      });  

      return {
        llistaJubilats,
        idioma,
        mostrarVeureMesResultats
      };
  },

  computed: {   
    styles_llista_jubilats() {
      if(this.$i18n.locale=='Castellà'){
        return 'llista-jubilats-castella';
      }
      else if(this.$i18n.locale=='Català'){
        return 'llista-jubilats-catala'
      }
      return 'llista-jubilats-angles'
    }     
  }

};
</script>