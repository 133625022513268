<template>
  <div>
    <div v-if="unitat['descripcio'+idioma+'_ac']" class="(index % 2) == 0 ? 'fonsBlanc_light' : 'fonsGris_light'">
      <div class="col-lg" style="margin-left:20px;margin-bottom:10px;margin-top:0px;">
        <div class="col-lg" style="margin-bottom:5px;margin-top:0px;">
          <a class="no-link link-level-one" @click="getDadesUnitat(unitat.id)">
          {{unitat['descripcio'+idioma+'_ac']}}
        </a><br> 
        </div>
      </div>	
    </div>
  </div>
</template>
<script>
import { toRefs , computed} from "vue";
import { useRouter } from 'vue-router';

export default {
  name: "Unitat",
  props: {
    dadesUnitat: Object,
    idiomaPagina: String
  },

  setup(props) {
      const { idiomaPagina , dadesUnitat } = toRefs(props);

      const router = useRouter()

      const unitat = computed(() => {
        
        return dadesUnitat?.value
      });

      const idioma = computed(() => {
          return idiomaPagina?.value
      });

      function getDadesUnitat(id){
        if(!(id==null || id=='undefined')){
          router.push({ path: '/dadesUnitat/'+id })
        }
      }

      return {
        unitat,
        idioma,
        getDadesUnitat
      };
  },

  computed: {
    styles_llista_persones() {
      if(this.$i18n.locale=='Castellà'){
        return 'llista-persones-castella';
      }
      else if(this.$i18n.locale=='Català'){
        return 'llista-persones-catala'
      }
      return 'llista-persones-angles'
    },
  } 

};
</script>