<template>
  <div>
    <div class="fonsBlanc_light">
      <div class="container" style="margin-top: 0px;">
        <div class="col-lg-12">
          {{$t('__sou_a__')}}: <a class="breadcrumb" @click="redireccionInicio()">{{$t('Inici')}}</a>&nbsp;>&nbsp;{{$t('Resultats de la cerca')}}
          <div v-if="data">
            <div class="col-lg">
              <div v-if="data.nomcognoms_ac" class="col-lg contenidorTitolSeccio">                            
                <div class="titolSeccio" style="color:#000;font-size: 1.3em;">{{data.nomcognoms_ac}}</div>
                <hr style="margin-top:0px;margin-left:0px;" >
              </div>
            </div> 
            <div class="row">
              <!-- <div class="col-lg-2" style="margin-bottom:10px;margin-top:0px;">
                <img class="img-fluid" :src="foto" style="border-radius: 50%;height: 150px;width: 150px;">
              </div> -->
              <div v-if="data.correu_usuari_txt && data.correu_domini_txt" class="col-lg-9" style="margin-left:20px;margin-bottom:10px;margin-top:0px;">
                <div class="col-lg" style="margin-bottom:10px;margin-top:0px;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="40" fill="rgb(158, 158, 158)" class="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                  </svg>
                  <span style="margin-left:15px; margin-right:-2px;">{{data.correu_usuari_txt}}</span>        
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentcolor" class="bi bi-at bi-gris" viewBox="0 0 16 16">
                    <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"/>
                  </svg><span style="margin-left:-3px;">{{data.correu_domini_txt}}</span>
                </div>


                <div v-if="data.vinculacions">
                  <div class="col-lg" style="margin-bottom:10px;margin-top:0px;">

                    <div v-if="Array.isArray(data.vinculacions)">
                  
                      <div v-for="vinculacio in data.vinculacions" :key="vinculacio.id">
                        <div v-if="vinculacio.tipus_personal_s=='PAS'">
                          <h6><b>{{$t('__pas__')}}</b><br></h6>
                        </div>
                      
                        <div v-if="vinculacio.tipus_personal_s=='PDI'">
                          <h6><b>{{$t('__pdi__')}}</b><br></h6>  
                          <div v-if="vinculacio['categoria'+idioma+'_txt']">
                            <!-- <span class="descripcioDades">{{$t('__categoria__')}}</span><span>{{ vinculacio["categoria"+idioma+"_txt"] }}<br></span> -->
                            <span>{{ vinculacio["categoria"+idioma+"_txt"] }}<br></span>
                          </div>                                            
                        </div>

                        <div v-if="vinculacio['unitat'+idioma+'_txt']">
                          <i class="bi bi-diagram-3 bi-gris" style="font-size:22px;"></i>
                          <a class="no-link" @click="getDadesUnitat(vinculacio.unitat_s)" style="margin-left:10px;">{{vinculacio["unitat"+idioma+"_txt"]}}</a><br>
                        </div>

                        <table v-if="vinculacio['edifici'+idioma+'_ac']">
                          <row>
                            <td style="padding-left:0px;vertical-align:middle;">                          
                              <i class="bi bi-geo-alt bi-gris" style="font-size:25px;margin-left: -2px;"/>
                            </td>
                            <td style="padding-left:4px;">
                              <span>&nbsp;{{vinculacio["campus"+idioma+"_ac"] }}</span><br>	
                              
                              <span>&nbsp;{{vinculacio["edifici"+idioma+"_ac"] }}</span><br>
                              <div v-if="vinculacio.adreca_txt">
                                <span>&nbsp;{{ vinculacio.adreca_txt }}</span><br> 
                              </div>
                              <div v-if="vinculacio.planta_despatx_txt">
                                <span>&nbsp;{{vinculacio.planta_despatx_txt }}</span><br>
                              </div> 
                              <span>&nbsp;<a :href="'https://maps.app.goo.gl/'+vinculacio.urlmap_txt" target="_blank" class="no-link descripcioDades">{{$t('__google_maps__')}}</a><a :href="'https://maps.app.goo.gl/'+vinculacio.urlmap_txt" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a></span><br>                 
                            </td>
                          </row>
                        </table>
                        
                        <div v-if="vinculacio.telefon_txt">
                          <i class="bi bi-telephone bi-gris" style="font-size:18px"></i><span style="margin-left:15px;">{{ vinculacio.telefon_txt }}</span><br>
                        </div>

                        <br>

                        <div v-if="vinculacio.tipus_personal_s=='PDI'">              
                          <div v-if="data.urlfutur_txt">
                            <a :href="data.urlfutur_txt+'?locale='+this.idiomaUrl" target="_blank" class="no-link descripcioDades">{{$t('__futur__')}}</a><a :href="vinculacio.urlfutur_txt+'?locale='+this.idiomaUrl" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a><br>                                          
                          </div>
                          <div v-if="data.perfil_apren_txt">
                            <a :href="'https://apren.upc.edu/'+idiomaUrl+'/professorat/'+data.perfil_apren_txt" target="_blank" class="no-link descripcioDades">{{$t('__apren__')}}</a><a :href="'https://apren.upc.edu/'+idiomaUrl+'/professorat/'+data.perfil_apren_txt" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a><br>                 
                          </div>
                        </div>
                        <br>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="data.vinculacions.tipus_personal_s=='PAS'">
                        <h6><b>{{$t('__pas__')}}</b><br></h6>
                      </div>
                      <div v-if="data.vinculacions.tipus_personal_s=='PDI'">
                        <h6><b>{{$t('__pdi__')}}</b><br></h6>
                        <div v-if="data.vinculacions['categoria'+idioma+'_txt']">
                          <!-- <span class="descripcioDades">{{$t('__categoria__')}}</span><span>{{ data.vinculacions["categoria"+idioma+"_txt"] }}<br></span> -->
                          <span>{{ data.vinculacions["categoria"+idioma+"_txt"] }}<br></span>
                        </div>
                      </div>
                      <div v-if="data.vinculacions['unitat'+idioma+'_txt']">
                        <i class="bi bi-diagram-3 bi-gris" style="font-size:22px;"></i>
                          <a class="no-link" @click="getDadesUnitat(data.vinculacions.unitat_s)" style="margin-left:10px;">{{data.vinculacions["unitat"+idioma+"_txt"]}}</a><br>
                      </div>

                      
                      <table v-if="data.vinculacions['edifici'+idioma+'_ac']">
                        <row>
                          <td style="padding-left:0px;vertical-align:middle;">
                            <i class="bi bi-geo-alt bi-gris" style="font-size:25px;margin-left: -2px;"/>
                          </td>
                          <td style="padding-left:4px;">
                              <div v-if="data.vinculacions['campus'+idioma+'_ac']">
                                <span>&nbsp;{{ data.vinculacions["campus"+idioma+"_ac"] }}</span><br>   
                              </div>                                                      
                            
                              <div v-if="data.vinculacions['edifici'+idioma+'_ac']">
                                <span>&nbsp;{{ data.vinculacions["edifici"+idioma+"_ac"] }}</span><br>                       
                              </div>                          

                              <div v-if="data.vinculacions.adreca_txt">
                                <span>&nbsp;{{ data.vinculacions.adreca_txt }}</span><br> 
                              </div>
                              <div v-if="data.vinculacions.planta_despatx_txt">
                                <span>&nbsp;{{ data.vinculacions.planta_despatx_txt }}</span><br>
                              </div>                     
                                 
                              <span>&nbsp;<a :href="'https://maps.app.goo.gl/'+data.vinculacions.urlmap_txt" target="_blank" class="no-link descripcioDades">{{$t('__google_maps__')}}</a><a :href="'https://maps.app.goo.gl/'+data.vinculacions.urlmap_txt" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a></span><br>                 
                              
                            
                          </td>
                        </row>
                      </table>
                      

                      <div v-if="data.vinculacions.telefon_txt">
                        <i class="bi bi-telephone bi-gris" style="font-size:18px"></i><span style="margin-left:15px;">{{ data.vinculacions.telefon_txt }}</span><br>
                      </div>                  
                      <br>
                      <div v-if="data.vinculacions.tipus_personal_s=='PDI'">                                   
                        <div v-if="data.urlfutur_txt">
                          <a :href="data.urlfutur_txt+'?locale='+this.idiomaUrl" target="_blank" class="no-link descripcioDades">{{$t('__futur__')}}</a><a :href="data.urlfutur_txt+'?locale='+this.idiomaUrl" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a><br>
                        </div>                 
                        <div v-if="data.perfil_apren_txt">
                          <a :href="'https://apren.upc.edu/'+idiomaUrl+'/professorat/'+data.perfil_apren_txt" target="_blank" class="no-link descripcioDades">{{$t('__apren__')}}</a><a :href="'https://apren.upc.edu/'+idiomaUrl+'/professorat/'+data.perfil_apren_txt" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a><br>                 
                        </div>  
                      </div>
                      
                      <div v-if="dataCarrecs">
                        <div v-if="dataCarrecs['descripcio'+idioma+'_ac']">
                          <br>
                          <h6><b>{{$t('Carrecs')}}</b></h6>                
                          <i class="bi bi-person-check bi-gris" style="font-size:22px;"></i>&nbsp;<span style="margin-left:8px;"><a class="no-link" @click="getDadesCarrec(dataCarrecs.id)">{{ dataCarrecs['descripcio'+idioma+'_ac'] }}</a></span><br>			
                        </div>          
                      </div>

                    </div>
                  </div>


                </div>                                       
              </div>

            </div>

          </div>

          <div v-if="data==null" style="margin-top:20px;">            
            <h3>{{$t('__sense_coincidencies__')}}</h3>          
          </div>

        </div>
      </div>    
    </div>
    <PeuPagina v-if="endFetch"/>
  </div>
</template>

<script>

const idiomas = {English:"EN",Castellà:"ES",Català:"CA"};
import PeuPagina from "@/components/PeuPagina.vue"
export default {
  components:{
    PeuPagina       
  },

  data: function () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      apiURL: 'api',
      data: [],
      dataCarrecs: [],
      idioma: idiomas[this.$i18n.locale], //Utilizado para el idioma de los datos cargados de solr,
      idiomaUrl: "",
      idiomaPagina: this.$i18n.locale,
      idGauss: this.$route.params.idGauss,
      //messageUrl : this.$route.query.idGauss
      foto:[],
      urlFutur: "",
      endFetch: false
    }
  },

  created() {   
    this.fetchData();
  },

  watch: { 
    '$i18n.locale': {
      handler: function() {
        this.cambiarIdiomaVariables()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    redireccionInicio(){
      this.$router.push({ path: '/' })
    },
    cambiarIdioma(idioma) {
      this.$i18n.locale = idioma
      this.idiomaPagina = this.$i18n.locale
    },
    cambiarIdiomaVariables() {
      this.idioma = idiomas[this.$i18n.locale]
      this.idiomaUrl = this.idioma.toLowerCase().trim()
    },

    fetchData: async function () {
      try {
        await Promise.all([
          this.fetchSolrData(),
          this.fetchSolrCarrecs(),
          //this.fetchFoto()
        ])
      } catch (error) {
        console.log(error)
      }
    },

    fetchSolrData: function () {
      fetch(`${this.backendURL}/${this.apiURL}/personaByIdGauss?idGauss=${this.idGauss}`)
          .then(r => r.json())
          .then(json => {

            if(json?.response?.numFound==0){
              this.data=null
            }
            else{
              this.data = json.response.docs[0]
            }

            //this.data = json?.response?.docs[0]
            //this.endFetch=true  
            setTimeout(() => {this.endFetch = true;}, "50");          
          })
          .catch(error => console.error('Error retrieving DADES_PERSONA_BY_ID_GAUSS: ' + error))
    },
    fetchSolrCarrecs: function () {
      fetch(`${this.backendURL}/${this.apiURL}/carrecsByIdGauss?idGauss=${this.idGauss}`)
          .then(r => r.json())
          .then(json => {
            this.dataCarrecs = json?.response?.docs[0]
            //this.endFetch=true
            setTimeout(() => {this.endFetch = true;}, "50");
          })
          .catch(error => console.error('Error retrieving DADES_PERSONA_CARRECS: ' + error))
    },    

    fetchFoto: function () {
      fetch(`${this.backendURL}/${this.apiURL}/foto?idGauss=${this.idGauss}`)
          .then(r => r.json())
          .then(json => {
            this.foto = json.foto
          })
          .catch(error => console.error('Error retrieving DADES_PERSONA_FOTO: ' + error))      
    },

    getDadesCarrec: function(id){
      this.$router.push({ path: '/dadesCarrec/'+id })
    },
    getDadesUnitat: function(id){
      //alert("id"+id)
      this.$router.push({ path: '/dadesUnitat/'+id })
    },

  },
}
</script>

<style>

  a:not(.dropdown-item, .ui-menu-item-wrapper, .link-peu, .avis-legal) {
    /* color: #007BC1; */
    font-size: 1rem;
    font-weight: 300;
  }

  .open{
    background-color: green;
  }

  .closed{
    background-color: red;
  }
  .bi-gris{
    color: rgb(158, 158, 158);
  }

</style>