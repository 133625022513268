<template>
  <div>
    <div class="col-lg" style="margin-left:20px;margin-bottom:10px;margin-top:0px;">
      <div class="col-lg" style="margin-bottom:5px;margin-top:0px;">            
        <a class="no-link link-level-one" @click="getDadesJubilat(jubilat.id)" >
          {{jubilat.cognomsnom_ac}}
        </a><br>
      </div>
      <div v-if="jubilat['unitat_org'+idioma+'_ac']" class="col-lg-6" style="padding-left: 0px;margin-bottom:12px;margin-top:0px;color:#212529b8;">                                    
        <div class="" >{{jubilat["unitat_org"+idioma+"_ac"]}}</div>
      </div>
    </div>    
  </div>
</template>
<script>
import { toRefs , computed} from "vue";
import { useRouter } from 'vue-router';

export default {
  name: "Jubilat",
  props: {
    dadesJubilat: Object,
    idiomaPagina: String
  },

  setup(props) {
      const { idiomaPagina , dadesJubilat } = toRefs(props);

      const router = useRouter()

      const jubilat = computed(() => {
        return dadesJubilat?.value
      });

      const idioma = computed(() => {
          return idiomaPagina?.value
      });

      function getDadesJubilat(id){
        if(!(id==null || id=='undefined')){
          router.push({ path: '/dadesJubilat/'+id })
        }             
      }    

      return {
        jubilat,
        idioma,
        getDadesJubilat
      };
  },


};
</script>