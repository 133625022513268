<template>
  <div class="fonsBlanc_light">
    <div class="container">
      <div class="col-lg-12">
        {{$t('__sou_a__')}}: <a class="breadcrumb" @click="redireccionInicio()">{{$t('Inici')}}</a>&nbsp;>&nbsp;{{$t('Ajuda')}}
        <div class="col-lg contenidorTitolSeccio" style="margin-bottom: 35px;">
          <div class="titolSeccio">{{$t('__ajuda_text_titol__')}}</div>
          <hr :class="styles_text_idioma" >
        </div>
      </div>
      <div class="col-lg-12">
        <div class="lopd">
          <div v-html="$t('__ajuda_text_cos__')"></div>
        </div>
      </div>
    </div>
    <PeuPagina />
  </div>
</template>

<script>
import PeuPagina from "@/components/PeuPagina.vue"

export default {
  components:{
    PeuPagina          
  },
  data: function () {
    // console.log(process.env)
    return {
      idiomaPagina: this.$i18n.locale,
      // open: true
    }
  },
  methods: {
    cambiarIdioma(idioma) {
      this.$i18n.locale = idioma
      this.idiomaPagina = this.$i18n.locale
    },
    redireccionInicio(){
      this.$router.push({ path: '/' })
    },
  },

  computed: {
    styles_text_idioma() {
      if(this.$i18n.locale=='Castellà'){
        return 'ajuda-text-castella';
      }
      else if(this.$i18n.locale=='Català'){
        return 'ajuda-text-catala'
      }
      return 'ajuda-text-angles'
    },
  }

}
</script>

<style>

.ajuda-text-catala{
  margin-top:-20px;
  margin-left:345px;
}
.ajuda-text-castella{
  margin-top:-20px;
  margin-left:355px;
}
.ajuda-text-angles{
  margin-top:-20px;
  margin-left:275px;
}

</style>

