<template>
  <div class="fonsBlanc_light">
    <div class="container" style="margin-top: 0px;">
      <div class="col-lg-12">

        {{$t('__sou_a__')}}: <a class="breadcrumb" @click="redireccionInicio()">{{$t('Inici')}}</a>&nbsp;>&nbsp;{{$t('Resultats de la cerca')}}

        <div v-if="!avisCerca && (totalResultats>1)">
          <LlistaCarrecs v-if="(dataCarrecs?.numFound)>0" :idioma-pagina="idioma" :carrecs="dataCarrecs" />
          <div class="col-lg-12" v-if="(dataCarrecs?.numFound)>limitResultats">
            <div class="plusButton" style="padding-left: 15px;margin-bottom:10px;margin-top:0px">
              <a class="step link-hand" @click="getDetallResultats('CARREC')"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus" viewBox="0 2 16 16">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>&nbsp;{{$t('__veure_mes_resultats__')}}</a>
            </div>
          </div> 

          <LlistaPersones v-if="(dataPersones?.numFound)>0" :idioma-pagina="idioma" :persones="dataPersones" />
          <div class="col-lg-12" v-if="(dataPersones?.numFound)>limitResultats">
            <div class="plusButton" style="padding-left: 15px;margin-bottom:10px;margin-top:10px">
              <a class="step link-hand" @click="getDetallResultats('PERSONA')"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus" viewBox="0 2 16 16">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>&nbsp;{{$t('__veure_mes_resultats__')}}</a>
            </div>
          </div>    
                
          <LlistaUnitats v-if="(dataUnitats?.numFound)>0" :idioma-pagina="idioma" :unitats="dataUnitats" />
          <div class="col-lg-12" v-if="(dataUnitats?.numFound)>limitResultats">
            <div class="plusButton" style="padding-left: 15px;margin-bottom:10px;margin-top:0px">
              <a class="step link-hand" @click="getDetallResultats('UNITAT')"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus" viewBox="0 2 16 16">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>&nbsp;{{$t('__veure_mes_resultats__')}}</a>
            </div>
          </div> 

          <LlistaJubilats v-if="(dataJubilats?.numFound)>0" :idioma-pagina="idioma" :jubilats="dataJubilats"  />
          <div class="col-lg-12" v-if="(dataJubilats?.numFound)>limitResultats">
            <div class="plusButton" style="padding-left: 15px;margin-bottom:10px;margin-top:0px">
              <a class="step link-hand" @click="getDetallResultats('JUBILAT')"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus" viewBox="0 2 16 16">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>&nbsp;{{$t('__veure_mes_resultats__')}}</a>
            </div>
          </div> 
        </div>


        <div v-if="avisCerca" style="margin-top:20px;">
            <h3>{{$t('__avis_cerca__')}}</h3>
        </div> 
        <div v-if="endCarrecs && endJubilats && endPersones && endUnitats">
          <div v-if="((dataCarrecs?.numFound) + (dataPersones?.numFound) + (dataUnitats?.numFound) + (dataJubilats?.numFound))==1">
            {{ getDadesRedireccio() }}
          </div>
          <div v-if="!avisCerca && ((dataCarrecs?.numFound)==-1) && ((dataPersones?.numFound)==-1) && ((dataUnitats?.numFound)==-1) && ((dataJubilats?.numFound)==-1)" style="margin-top:20px;">
            <h3>{{$t('__caracters_insuficients__')}}</h3>
          </div>             
          <div v-if="!avisCerca && ((dataCarrecs?.numFound)+(dataPersones?.numFound)+(dataUnitats?.numFound)+(dataJubilats?.numFound))==0" style="margin-top:20px;">
            <h3>{{$t('__sense_coincidencies__')}}</h3>
          </div>         
        </div>

      </div>
    </div>
    <PeuPagina v-if="endCarrecs && endPersones && endUnitats && endJubilats"/>
  </div>
</template>


<script>
const idiomas = {English:"EN",Castellà:"ES",Català:"CA"};

import LlistaCarrecs from "@/components/LlistaCarrecs.vue"
import LlistaPersones from "@/components/LlistaPersones.vue"
import LlistaUnitats from "@/components/LlistaUnitats.vue"
import LlistaJubilats from "@/components/LlistaJubilats.vue"
import PeuPagina from "@/components/PeuPagina.vue"

export default{

  components:{
    LlistaCarrecs,
    LlistaPersones,
    LlistaUnitats,
    LlistaJubilats,
    PeuPagina
  },
  
  data: function(){
    return{
      backendURL: process.env.VUE_APP_BACKEND_URL,
      apiURL: 'api',
      limitResultats: 5,
      dataCarrecs: [],
      dataPersones: [],
      dataUnitats: [],
      dataJubilats: [],
      veureTotsCarrecs: 0,
      veureTotsPersones: 0,
      veureTotsUnitats: 0,
      veureTotsJubilats: 0,      
      cerca: this.$route.params.pernr,
      searchField : this.$route.query.searchField,
      idioma: idiomas[this.$i18n.locale], //Utilizado para el idioma de los datos cargados de solr
      avisCerca: false,
      push_path: "",
      push_id: "",
      endCarrecs: false,
      endPersones: false,
      endUnitats: false,
      endJubilats: false,
      totalResultats: 0
    }
 
  },

  watch: { 
    '$route.query.searchField': {
      //handler: function(search) {
      handler: function() {
        //alert(this.$route.query.searchField)
        this.push_path=""
        this.push_id=""
        this.searchField = this.$route.query.searchField
        this.endCarrecs= false,
        this.endPersones= false,
        this.endUnitats= false,
        this.endJubilats= false

        if(this.$route.query.searchField===""){
          this.avisCerca = true
          this.endCarrecs= true,
          this.endPersones= true
          this.endUnitats= true
          this.endJubilats= true
        }else{
          this.avisCerca = false
          this.fetchData();
        }  
      },
      deep: true,
      immediate: true
    },
    '$i18n.locale': {
      //handler: function(search) {
      handler: function() {
        this.cambiarIdiomaVariables()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    redireccionInicio(){
      this.$router.push({ path: '/' })
    },
    cambiarIdiomaVariables() {
      this.idioma = idiomas[this.$i18n.locale]
    },    

    fetchData: async function () {
      try {
        await Promise.all([
          this.totalResultats = 0,
          this.fetchSolrCarrecs(),
          this.fecthSolrPersones(),
          this.fecthSolrUnitats(),
          this.fecthSolrJubilats()

        ])
      } catch (error) {
        console.error(error)
      }
    },

    fetchSolrCarrecs: function () {
      let tipusCerca = "CARREC"
      //fetch(`${this.backendURL}/api/funcion7?searchField=${this.$route.query.searchField}&tipusCerca=${tipusCerca}&idioma=${this.idioma}&max=5`)
      fetch(`${this.backendURL}/api/carrecs?searchField=${this.$route.query.searchField}&tipusCerca=${tipusCerca}&idioma=${this.idioma}&max=5`)
          .then(r => r.json())
          .then(json => {           
            this.dataCarrecs=null
            if(json.response){
              
              this.dataCarrecs = json.response

              if((this.dataCarrecs?.numFound)==1){
                this.push_path="dadesCarrec"
                this.push_id=this.dataCarrecs.docs[0].id            
              }

              this.totalResultats = this.totalResultats + this.dataCarrecs?.numFound

// console.log("JSON_RESPONSE_1")
// console.log(this.dataCarrecs)
// console.log("JSON_RESPONSE_2")
              // this.veureTotsCarrecs=0
              // this.dataCarrecs = json.response.docs
              // if(this.dataCarrecs.length==0){
              //   this.dataCarrecs=null
              //   this.veureTotsCarrecs=0
              // }            
              // else{
              //   if(this.dataCarrecs.length==1){
              //     this.push_path="dadesCarrec"
              //     this.push_id=this.dataCarrecs[0].id
              //   }                  
              //   this.veureTotsCarrecs=json.response.numFound              
              // } 
            }
            
            
            else{
              this.dataCarrecs=null
              this.veureTotsCarrecs=0
            }
            this.veureTotsCarrecs=json?.response?.numFound
            this.endCarrecs= true
                      
          })
          .catch(error => console.error('Error retrieving CARREC: ' + error))
          // this.getTrazaTemporal("CARRECS_FIN")
    },
    fecthSolrPersones: function () {
      let tipusCerca = "PERSONA"
      //fetch(`${this.backendURL}/api/funcion5?searchField=${this.$route.query.searchField}&tipusCerca=${tipusCerca}&max=5`)
      fetch(`${this.backendURL}/api/persones?searchField=${this.$route.query.searchField}&tipusCerca=${tipusCerca}&max=5`)
          .then(r => r.json())
          .then(json => { 
            this.dataPersones=null         
            if(json.response){
              
              this.dataPersones = json.response

              if((this.dataPersones?.numFound)==1){
                this.push_path="dadesPersona"
                this.push_id=this.dataPersones.docs[0].idgauss_s            
              }

              this.totalResultats = this.totalResultats + this.dataPersones?.numFound
              // this.veureTotsPersones=0
              // this.dataPersones = json.response.docs
              // if(this.dataPersones.length==0){
              //   this.dataPersones=null
              //   this.veureTotsPersones=0
              // }
              // else{
              //   if(this.dataPersones.length==1){
              //     this.push_path="dadesPersona"
              //     this.push_id=this.dataPersones[0].idgauss_s
              //   }
              //   this.veureTotsPersones=json.response.numFound
              // } 
            }else{
              this.dataPersones=null
              this.veureTotsPersones=0
            }
            this.veureTotsPersones=json?.response?.numFound
            this.endPersones= true

          })
          .catch(error => console.error('Error retrieving PERSONA: ' + error))
          // this.getTrazaTemporal("PERSONES_FIN")
    },
    
    fecthSolrUnitats: function () {
      let tipusCerca = "UNITAT"
      //fetch(`${this.backendURL}/api/funcion10?searchField=${this.$route.query.searchField}&tipusCerca=${tipusCerca}&idioma=${this.idioma}&max=5`)
      fetch(`${this.backendURL}/api/unitats?searchField=${this.$route.query.searchField}&tipusCerca=${tipusCerca}&idioma=${this.idioma}&max=5`)
          .then(r => r.json())
          .then(json => {
            this.dataUnitats=null           
            if(json.response){
              
              this.dataUnitats = json.response

              if((this.dataUnitats?.numFound)==1){
                this.push_path="dadesUnitat"
                this.push_id=this.dataUnitats.docs[0].id                     
              }

              this.totalResultats = this.totalResultats + this.dataUnitats?.numFound
              // this.veureTotsUnitats=0
              // this.dataUnitats = json.response.docs
              // if(this.dataUnitats.length==0){
              //   this.dataUnitats=null
              //   this.veureTotsUnitats=0
              // }
              // else{
              //   if(this.dataUnitats.length==1){
              //     this.push_path="dadesUnitat"
              //     this.push_id=this.dataUnitats[0].id
              //   }
              //   this.veureTotsUnitats=json.response.numFound
              // }
            }else{
              this.dataUnitats=null
              this.veureTotsUnitats=0
            } 
            this.veureTotsUnitats=json?.response?.numFound
            this.endUnitats= true

          })
          .catch(error => console.error('Error retrieving UNITAT: ' + error))
    },

    fecthSolrJubilats: function () {
      let tipusCerca = "JUBILAT"
      //fetch(`${this.backendURL}/api/funcion5?searchField=${this.$route.query.searchField}&tipusCerca=${tipusCerca}&max=5`)
      fetch(`${this.backendURL}/api/persones?searchField=${this.$route.query.searchField}&tipusCerca=${tipusCerca}&max=5`)
          .then(r => r.json())
          .then(json => {            
            this.dataJubilats=null
            if(json.response){
              
              this.dataJubilats=json?.response

              if((this.dataJubilats?.numFound)==1){
                this.push_path="dadesJubilat"
                this.push_id=this.dataJubilats.docs[0].id
              }

              this.totalResultats = this.totalResultats + this.dataJubilats?.numFound
              // this.veureTotsJubilats=0
              // this.dataJubilats = json.response.docs
              // if(this.dataJubilats.length==0){
              //   this.dataJubilats=null
              //   this.veureTotsJubilats=0
              // }
              // else{
              //   if(this.dataJubilats.length==1){
              //     this.push_path="dadesJubilat"
              //     this.push_id=this.dataJubilats[0].id
              //   }
              //   this.veureTotsJubilats=json.response.numFound
              // } 
            }else{
              this.dataJubilats=null
              this.veureTotsJubilats=0
            }          
            this.veureTotsJubilats=json?.response?.numFound
            this.endJubilats= true
          })
          .catch(error => console.error('Error retrieving JUBILAT: ' + error))
    },    




   
    
    getDetallResultats: function (tipusCerca) {
      //this.$router.push('/'+tipusCerca.toLowerCase()+'?searchField='+this.searchField+'&tmstmp='+(new Date()).valueOf())
      this.$router.push('/'+tipusCerca.toLowerCase()+'?searchField='+this.searchField)
    },


    getDadesRedireccio: function(){
      if((this.veureTotsCarrecs+this.veureTotsJubilats+this.veureTotsPersones+this.veureTotsUnitats)==1){
        if(!(this.push_id==null || this.push_id=='undefined')){
          this.$router.push({ path: '/'+this.push_path+'/'+this.push_id })
        }     
      }            
    },

    getTrazaTemporal: function(msg){
      var f1 = new Date().toISOString()
      console.log("NUEVA TRAZA TEMPORAL - "+msg);
      console.log(f1);
    }
  },

  provide:{
    otroTexto: "HOLA VUE!!!",


  },

}
</script>
