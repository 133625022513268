import "bootstrap/dist/css/bootstrap.css"

// import "./assets/css/bootstrap.min.css"
import "./assets/css/bootstrap-3.2.0.css"
import "./assets/css/baseRLG.css"
import "./assets/css/bootstrap-icons.css"
import "./assets/css/jquery/ui/1.13.2/themes/smoothness/jquery-ui.min.css"
import "./assets/css/jquery/ui/1.13.2/themes/smoothness/theme.css"
import "./assets/css/main01.css"

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'



/* V 1.4.17 */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(ElementPlus)
app.mount('#app')
import "bootstrap/dist/js/bootstrap.js"