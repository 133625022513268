<template>
  <div>
    <div class="col-lg contenidorTitolSeccio">
      <div class="titolSeccio">{{$t('Unitats')}}</div>
      <hr :class="styles_llista_unitats">
    </div>

    <Unitat v-for="(unitat,index) in llistaUnitats" 
    :key="unitat.id" 
    :idioma-pagina="idioma" 
    :dades-unitat="unitat"
    :class="(index % 2) == 0 ? 'fonsBlanc_light' : 'fonsGris_light'"/> 
    
    
  </div>
  

</template>
<script>
import { toRefs , computed} from "vue";

import Unitat from "@/components/Unitat.vue"

export default {
  components:{
    Unitat
  },
  name: "LlistaUnitats",
  props: {
    unitats: Object,
    idiomaPagina: String
  },

  setup(props) {
      const { idiomaPagina , unitats } = toRefs(props);

      const idioma = computed(() => {
          return idiomaPagina?.value
      });

      const llistaUnitats = computed(() => {
        return unitats?.value?.docs
      });

      const mostrarVeureMesResultats = computed(() => {
        return ((unitats?.value?.numFound)>5)?true:false;
      });  

      return {
        llistaUnitats,
        idioma,
        mostrarVeureMesResultats
      };
  },

  computed: {
    styles_llista_unitats() {
      if(this.$i18n.locale=='Castellà'){
        return 'llista-unitats-castella';
      }
      else if(this.$i18n.locale=='Català'){
        return 'llista-unitats-catala'
      }
      return 'llista-unitats-angles'
    },
  } 

};
</script>
