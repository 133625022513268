<template>
  <div>
    <div class="fonsBlanc_light" style="margin-top:0px">
      <div class="container" style="margin-top: 5px;" >
        <div class="col-lg-12 " style="margin-bottom:10px;margin-top:10px;">
          <div id="upc_bcn_tech" >{{$t('__UPC_BCN_TECH__')}}</div>
        </div>
      </div>
    </div>  
    <div class="fonsGris2_light">
      <div class="container" >
        <div class="col-lg-12">
          <div class="col-lg contenidorTitolSeccio" style="margin-bottom: 35px;">
            <div class="titolSeccio">{{$t('__links_interes__')}}</div>
            <hr :class="styles_links_idioma" >
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-12 " style="margin-bottom:15px;">
            <ul>
              <li><a class="no-link" :href="$t('__web_upc_link__')" target="_blank">{{$t('__web_upc__')}}<span class="bi bi-box-arrow-up-right" style="margin-left: 5px;"></span></a><br/></li>
              <li><a class="no-link" :href="$t('__llista_membres_consell_direccio_link__')" target="_blank">{{$t('__llista_membres_consell_direccio__')}}<span class="bi bi-box-arrow-up-right" style="margin-left: 5px;"></span></a><br/></li>
              <li><a class="no-link" :href="$t('__centres_docents_link__')" target="_blank">{{$t('__centres_docents__')}}<span class="bi bi-box-arrow-up-right" style="margin-left: 5px;"></span></a><br/></li>
              <li><a class="no-link" :href="$t('__departaments_i_instituts_link__')" target="_blank">{{$t('__departaments_i_instituts__')}}<span class="bi bi-box-arrow-up-right" style="margin-left: 5px;"></span></a><br/></li>
              <li><a class="no-link" :href="$t('__llista_unitats_link__')" target="_blank">{{$t('__llista_unitats__')}}<span class="bi bi-box-arrow-up-right" style="margin-left: 5px;"></span></a><br/></li>
              <li><a class="no-link" :href="$t('__grup_upc_link__')" target="_blank">{{$t('__grup_upc__')}}<span class="bi bi-box-arrow-up-right" style="margin-left: 5px;"></span></a><br/></li>              
            </ul>
          </div>
        </div>
      </div>
    </div>
    <PeuPagina />
  </div>
</template>

<script>

import PeuPagina from "@/components/PeuPagina.vue"
  export default {
    components:{
      PeuPagina       
    },
  data: function () {
    return {
      idiomaPagina: this.$i18n.locale,
    }
  },
  methods: {
    cambiarIdioma(idioma) {
      this.$i18n.locale = idioma
      this.idiomaPagina = this.$i18n.locale
    }
  },

  computed: {
    styles_links_idioma() {
      if(this.$i18n.locale=='Castellà'){
        return 'links-idioma-castella';
      }
      else if(this.$i18n.locale=='Català'){
        return 'links-idioma-catala'
      }
      return 'links-idioma-angles'
    },
    // styles_avis_legal_idioma() {
    //   if(this.$i18n.locale=='Castellà'){
    //     return 'avis-legal-idioma-castella';
    //   }
    //   else if(this.$i18n.locale=='Català'){
    //     return 'avis-legal-idioma-catala'
    //   }
    //   return 'avis-legal-idioma-angles'
    // },
  }

}
</script>

<style scoped>

.links-idioma-catala{
  margin-top:-20px;
  margin-left:265px;
}
.links-idioma-castella{
  margin-top:-20px;
  margin-left:275px;
}
.links-idioma-angles{
  margin-top:-20px;
  margin-left:90px;
}

/* .avis-legal-idioma-catala{
  margin-top:-20px;
  margin-left:150px;
}
.avis-legal-idioma-castella{
  margin-top:-20px;
  margin-left:160px;
}
.avis-legal-idioma-angles{
  margin-top:-20px;
  margin-left:185px;
} */

@media (min-width: 992px) {
  #upc_bcn_tech{
    font-size: 2.0em;
    color: rgb(99, 99, 99);
  }
}

@media (max-width: 991px) {
  #upc_bcn_tech{
    font-size: 1.5em;
    color: rgb(99, 99, 99);
  }
}

@media (max-width: 768px) {
  #upc_bcn_tech{
    font-size: 1.1em;
    color: rgb(99, 99, 99);
  }
}

li{
  margin-bottom:5px;
}

ul {
  list-style-type: '- '; 
}

</style>

