<template>
  <div></div>
</template>

<script>

export default {
  
  created() {
    this.redirectPage();
  },
  methods: {
    redirectPage(){
      this.$router.push({ path: '/' })
    }
  },


}
</script>