<template>
  <div></div>
</template>

<script>

export default {
  
  created() {
    this.redirectPage();
  },

  data: function () {
    return {
      idiomaPagina: this.$i18n.locale,
      messageCabecera: this.$route.params.id,
      messageUrl : this.$route.query.id,
      id: this.$route.query.id
    }
  },
  methods: {
    redirectPage(){
      this.$router.push({ path: '/dadesUnitat/U_'+this.id })
    }
  },

}
</script>