<template>
  <div>
    <div class="col-lg contenidorTitolSeccio">
      <div class="titolSeccio">{{$t('Persones')}}</div>
      <hr :class="styles_llista_persones">
    </div>
    
    <Persona v-for="(persona,index) in llistaPersones" 
    :key="persona.id" 
    :idioma-pagina="idioma" 
    :dades-persona="persona"
    :class="(index % 2) == 0 ? 'fonsBlanc_light' : 'fonsGris_light'"/> 
        
  </div>

</template>
<script>
import { toRefs , computed } from "vue";

import Persona from "@/components/Persona.vue"

export default {
  components:{
    Persona
  },  
  name: "LlistaPersones",
  props: {
    persones: Object,
    idiomaPagina: String
  },

  setup(props) {
      const { idiomaPagina , persones } = toRefs(props);
      
      const idioma = computed(() => {
          return idiomaPagina.value
      });

      const llistaPersones = computed(() => {
        return persones?.value?.docs
      });

      const mostrarVeureMesResultats = computed(() => {
        return ((persones?.value?.numFound)>5)?true:false;
      });  

      return {
        llistaPersones,
        idioma,
        mostrarVeureMesResultats
      };
  },

  computed: {   
    styles_llista_persones() {
      if(this.$i18n.locale=='Castellà'){
        return 'llista-persones-castella';
      }
      else if(this.$i18n.locale=='Català'){
        return 'llista-persones-catala'
      }
      return 'llista-persones-angles'
    },   
  }

};
</script>