<template>
    <!-- LOPD RGPD -->
    <div class="fonsBlanc_light">
      <div class="container">
        <div class="col-lg-12">
          <div class="col-lg contenidorTitolSeccio" style="margin-bottom: 35px;">
            <div class="titolSeccio" style="font-size: 1.6em;">{{$t('__avis_legal__')}}</div>
            <hr :class="styles_avis_legal_idioma" >
          </div>
        </div>
        <div class="col-lg-12">
          <div class="lopd">
            <p v-html="$t('__avis_legal_text__')"></p>

          </div>
        </div>
      </div>
    </div>
    <div style="height:10px;">&nbsp;</div>
    <!-- Peu -->
    <footer>
      <div class="container peu">
        <div class="row">
          <div class=" col-lg-6 col-md-6 col-sm-12 text-left">
            {{$t('__UPC_BCN_TECH__')}}
          </div>
        
          <div class="d-none d-md-block col-lg-6 col-md-6 text-right">
          

            <a class="link-peu blank-no-fletxa" :href="$t('__accessibilitat_link__')" target="_blank"
                onclick="window.open(this.href,this.target,'width=750,height=550,resizable=yes,scrollbars=yes');return false"
                style="text-decoration:none;">
              {{$t('Accessibilitat')}}
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a class="link-peu blank-no-fletxa" :href="$t('__avis_legal_link__')" target="_blank"
                onclick="window.open(this.href,this.target,'width=750,height=550,resizable=yes,scrollbars=yes');return false"
                style="text-decoration:none;">
              {{$t('__avis_legal__')}}
            </a>
          </div>

          <div class="d-md-none col-12">
            Àrea de Personal &nbsp;&nbsp;&nbsp;&nbsp;

            <a class="link-peu blank-no-fletxa" :href="$t('__accessibilitat_link__')" target="_blank"
                onclick="window.open(this.href,this.target,'width=750,height=550,resizable=yes,scrollbars=yes');return false"
                style="text-decoration:none;">
              {{$t('Accessibilitat')}}
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a class="link-peu blank-no-fletxa" :href="$t('__avis_legal_link__')" target="_blank"
                onclick="window.open(this.href,this.target,'width=750,height=550,resizable=yes,scrollbars=yes');return false"
                style="text-decoration:none;">
              {{$t('__avis_legal__')}}
            </a>
          </div>

        </div>
      </div>
    </footer>    
</template>

<script>
export default {
  name: "PeuPagina",

  computed: {

      styles_avis_legal_idioma() {
        if(this.$i18n.locale=='Castellà'){
          return 'avis-legal-idioma-castella';
        }
        else if(this.$i18n.locale=='Català'){
          return 'avis-legal-idioma-catala'
        }
        return 'avis-legal-idioma-angles'
      }
    }
}
</script>

<style>

.avis-legal-idioma-catala{
  margin-top:-20px;
  margin-left:125px;
}
.avis-legal-idioma-castella{
  margin-top:-20px;
  margin-left:135px;
}
.avis-legal-idioma-angles{
  margin-top:-20px;
  margin-left:155px;
}

</style>